import { FC, ReactNode } from 'react'
import clsx from 'clsx'
import { Section as ISection, SectionStyle } from '@/types/site.interface'
import { sectionStyleMap } from './constants'
import SectionContent from './section-content'
import SectionHeading from './section-heading'

const styles = {
  container: 'mx-auto max-w-sm px-4 sm:max-w-lg sm:px-6 lg:max-w-3xl lg:px-8',
  section: (className: string, style: SectionStyle) =>
    clsx('relative py-10 sm:py-12 lg:py-20', sectionStyleMap[style], className),
}

interface SectionProps {
  children?: ReactNode
  contentClassName?: string
  className?: string
  id?: string
  section?: ISection
}

const Section: FC<SectionProps> = ({
  children,
  contentClassName,
  className,
  id,
  section = { heading: '', headingType: 'h2', subheading: '', summary: '', style: 'default' },
}) => {
  const { heading, headingType, subheading, summary, style = 'default' } = section
  return (
    <section id={id} className={styles.section(className, style)}>
      <SectionHeading heading={heading} headingType={headingType} subheading={subheading} summary={summary} />
      <div className={contentClassName}>
        <SectionContent>{children}</SectionContent>
      </div>
    </section>
  )
}

export default Section
