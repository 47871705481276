import { ArrowLeft, ArrowRight } from 'phosphor-react'
import Pagination from '@ignition/library/components/molecules/pagination'
import { BasePaginationButton } from './base-pagination-button'

export const PrevButton = () => {
  return (
    <div className="-mt-px flex w-0 flex-1">
      <Pagination.PrevButton>
        {({ disabled, href }) => (
          <BasePaginationButton className="pr-1" disabled={disabled} href={href}>
            <ArrowLeft className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
            Previous
          </BasePaginationButton>
        )}
      </Pagination.PrevButton>
    </div>
  )
}

export const NextButton = () => {
  return (
    <div className="-mt-px flex w-0 flex-1 justify-end">
      <Pagination.NextButton>
        {({ disabled, href }) => (
          <BasePaginationButton className="pl-1" disabled={disabled} href={href}>
            Next
            <ArrowRight className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
          </BasePaginationButton>
        )}
      </Pagination.NextButton>
    </div>
  )
}
