import type { AnchorHTMLAttributes, ButtonHTMLAttributes, FC, MouseEvent, ReactNode } from 'react'
import cx from 'clsx'
import tw from 'tailwind-styled-components'
import { ButtonVariant } from '@/types/site.interface'
import SafeLink from '@ignition/library/components/atoms/safe-link'

export type ButtonType = 'anchor' | 'button'

export interface Button {
  children: ReactNode | string
  className?: string
  disabled?: boolean
  submit?: boolean
  href?: string
  type?: ButtonType
  variant?: ButtonVariant
  onClick?: (e: MouseEvent) => void
  fullWidth?: boolean
  form?: string
}

interface StyledButton {
  disabled?: boolean
  fullWidth?: boolean
}

const StyledDefaultButton = tw.a<StyledButton>`
    inline-flex
    justify-center
    rounded-md
    border
    border-transparent
    py-3
    px-6
    font-medium
    text-center
    shadow-sm
    focus:outline-none
    transition
    bg-button-bg
    text-button-text
    ${({ fullWidth }) => (fullWidth ? 'w-full' : '')}
    ${(p) =>
      p.disabled
        ? 'opacity-25 cursor-default'
        : `hover:bg-button-bg-hover
        hover:text-button-text-hover`}
`

const StyledContrastButton = tw.a<StyledButton>`
    inline-flex
    justify-center
    rounded-md
    py-3
    px-4
    font-medium
    text-center
    shadow
    bg-buttoncontrast-bg
    text-buttoncontrast-text
    transition-colors
    ${(p) =>
      p.disabled
        ? 'opacity-25 cursor-default'
        : `hover:bg-buttoncontrast-bg-hover
        hover:text-buttoncontrast-text-hover`}
`

const StyledOutlineButton = tw.a<StyledButton>`
    inline-flex
    items-center
    rounded-md
    border
    border-button-bg
    py-2
    px-4
    text-sm
    font-medium
    text-button-bg
    shadow-sm
    focus:outline-none
    ${(p) =>
      p.disabled
        ? 'opacity-25 cursor-default'
        : `hover:border-button-bg-hover
        hover:text-button-bg-hover`}
`

export const Button: FC<Button> = ({
  children,
  className,
  disabled = false,
  type = 'anchor',
  submit,
  href,
  variant = 'default',
  onClick,
  fullWidth,
  form,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const Component: FC<AnchorHTMLAttributes<Button>> | FC<ButtonHTMLAttributes<Button>> | any =
    variant === 'contrast' ? StyledContrastButton : variant === 'outline' ? StyledOutlineButton : StyledDefaultButton

  const classes = cx({ 'w-full': fullWidth }, className)

  return type === 'anchor' ? (
    <Component
      href={href}
      $as={SafeLink}
      disabled={disabled}
      className={classes}
      onClick={!disabled ? onClick : undefined}
    >
      {children}
    </Component>
  ) : (
    <Component
      $as="button"
      type={submit ? 'submit' : undefined}
      disabled={disabled}
      className={classes}
      onClick={!disabled ? onClick : undefined}
      form={form}
    >
      {children}
    </Component>
  )
}
