import { ReactNode } from 'react'
import { PaginationContext } from './constants'
import usePagination from './hooks/use-pagination'
import { BasePagination } from './interface'
import { NextButton, PreviousButton } from './nav-buttons'
import PaginationList from './pagination-list'

export interface PaginationProps extends BasePagination {
  href: string
  className?: string
  children?: ReactNode
}

const Pagination = ({ className, children, ...props }: PaginationProps) => {
  const pagination = usePagination(props)

  return (
    <PaginationContext.Provider value={pagination}>
      <div className={className}>{children}</div>
    </PaginationContext.Provider>
  )
}

Pagination.List = PaginationList
Pagination.NextButton = NextButton
Pagination.PrevButton = PreviousButton

export default Pagination
