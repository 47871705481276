import { FC, useContext } from 'react'
import { PaginationContext } from './constants'

export interface NavButtonProps {
  children: (props: { href?: string; disabled: boolean }) => JSX.Element
}

export const NextButton: FC<NavButtonProps> = ({ children }) => {
  return <BaseNavButton isNext>{children}</BaseNavButton>
}

export const PreviousButton: FC<NavButtonProps> = ({ children }) => {
  return <BaseNavButton>{children}</BaseNavButton>
}

interface BaseNavButtonProps extends NavButtonProps {
  isNext?: boolean
}

const BaseNavButton: FC<BaseNavButtonProps> = ({ children, isNext }) => {
  const { hasNextPage, hasPrevPage, href, currentPage } = useContext(PaginationContext)

  const disabled = isNext ? !hasNextPage : !hasPrevPage
  const targetHref = `${href}/page/${isNext ? currentPage + 1 : currentPage - 1}`
  return children({
    disabled,
    href: disabled ? undefined : targetHref,
  })
}
