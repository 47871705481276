import { FC, useContext } from 'react'
import { PaginationContext } from './constants'
import { PageButtonProps } from './interface'

interface PaginationListProps {
  PageButton: FC<PageButtonProps>
  Separator: FC<{}>
  className?: string
}

const PaginationList: FC<PaginationListProps> = ({ PageButton, Separator, className }) => {
  const { paginationElements, href } = useContext(PaginationContext)

  const renderPageButton = (index: number, active: boolean, key: number) => {
    return (
      <PageButton key={key} href={`${href}/page/${index}`} active={active}>
        {index + 1}
      </PageButton>
    )
  }

  return (
    <div className={className}>
      {paginationElements.map((element, i) => {
        const { index, active } = element
        if (typeof index === 'number') {
          return renderPageButton(index, active, i)
        }
        return <Separator key={i} />
      })}
    </div>
  )
}

export default PaginationList
